<template>
  <div>
    <h1>Google Docs Viewer</h1>
    <iframe
      :src="docLink"
      width="100%"
      height="900px"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import AES from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
export default {
  props: {
    arquivo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      docLink:null,
       
    };
  },
  created() {
    this.descriptografarId();
  },
   methods: {
    criptografarId(documentoId) {
      var iv = CryptoJS.lib.WordArray.random(16);
      var key = CryptoJS.enc.Utf8.parse(
        "GtMRHr2gIpTXDpNSEpFxdyb5wB4GMfYAShmjdQpe6gCzzYwhO8hCNIvZ25s3DGbm"
      );
      var ciphertext = CryptoJS.AES.encrypt(documentoId, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Concatenar o IV com o texto cifrado
      var encryptedData = iv.concat(ciphertext.ciphertext);

      return encryptedData;
    },

    descriptografarId() {
      var Sha256 = CryptoJS.SHA256;
      var Hex = CryptoJS.enc.Hex;
      var Utf8 = CryptoJS.enc.Utf8;
      var Base64 = CryptoJS.enc.Base64;
      var AES = CryptoJS.AES;

      var ciphertext = this.arquivo;
       
      var key = Utf8.parse(Sha256("test").toString(Hex).substr(0, 32));
      // var iv = Utf8.parse(Sha256("test").toString(Hex).substr(0, 16));
      // Supondo que o IV tem 16 bytes
      let stringba = atob(ciphertext);
      let iv = Utf8.parse(stringba.slice(0, 16));
      let ciphertext2 = btoa(stringba.slice(16));
      var decrypted = CryptoJS.AES.decrypt(ciphertext2, key, {
        iv: iv,
      });
     let link  = decrypted.toString(Utf8);
     this.docLink = "https://docs.google.com/document/d/"+link+"/edit"
    },
  },
  mounted() {
    // Carrega o cliente da API JavaScript do Google ao montar o componente
    // Aqui você pode adicionar a lógica para carregar o cliente da API se necessário
  },
};
</script>

<style>
/* Estilos opcionais aqui */
iframe {
  border: none;
}
</style>
