<template>
  <v-file-input
    multiple
    v-model="file"
    :label="text"
    truncate-length="15"
  ></v-file-input>
</template>

<script>
// import base64Files from "@/core/mixins/base64Files";
import ApiService from "@/core/services/api.service";

export default {
  name: "InputFile",
  // mixins: [base64Files],
  props: {
    text: {
      default: "Upload",
      type: String,
    },
  },
  data() {
    return {
      file: [],
      loading: false,
    };
  },
  methods: {
    async submit() {
      return new Promise((resolve) => {
        if (!this.file) {
          resolve();
          return;
        }
        this.loading = true;
        let i = 0;
        const formData = new FormData();
        if (this.file) {
          for (i = 0; i < this.file.length; i++)
            formData.append("file[" + i + "]", this.file[i]);
        }

        ApiService.post("arquivo", formData)
          .then((res) => {
            // console.log(res)
            resolve(!res.data.id ? res.data : res.data.id);
            this.file = null;
          })
          .finally(() => (this.loading = false));
      });
    },
    // base64File() {
    //   return this.fileToBase64(this.file);
    // },
    // async handleOnInput() {
    //   this.loading = true;
    //   let file = await this.base64File();
    //   this.$emit("input", file);
    //   this.loading = false;
    // },
  },
};
</script>
